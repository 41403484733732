import { InfoItemVertical } from "@bhawanadevteam/react-core";
import React from "react";
import { Col, Row } from "react-bootstrap";

export const KontakSection = ({ data }) => {
  return (
    <Row>
      <Col>
        <InfoItemVertical label="No. Telp" text={data?.telepon ?? "-"} />
        <InfoItemVertical label="Email" text={data?.email ?? "-"} />
      </Col>
    </Row>
  );
};
