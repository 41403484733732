import { InfoItemVertical } from "@bhawanadevteam/react-core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert, RupiahConvert } from "utilities";

export const AdministratifSection = ({ data }) => {
  return (
    <Row>
      <Col>
        <InfoItemVertical
          label="NIK"
          text={DateConvert(new Date(data?.nik))?.detail ?? "-"}
        />
      </Col>
      <Col>
        <InfoItemVertical label="No. NPWP" text={data?.npwp ?? "-"} />
      </Col>
    </Row>
  );
};
