import { InfoItemVertical } from "@bhawanadevteam/react-core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert, RupiahConvert } from "utilities";

export const BankSection = ({ data }) => {
  return (
    <Row>
      <Col>
        <InfoItemVertical label="Bank" text={data?.nama_bank ?? "-"} />
        <InfoItemVertical
          label="No. Rekening"
          text={data?.no_rekening ?? "-"}
        />
        <InfoItemVertical label="Atas Nama" text={data?.atas_nama ?? "-"} />
      </Col>
    </Row>
  );
};
