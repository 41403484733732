// React
import { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

// Component
import {
  DataStatus,
  PopUpAlert,
  SelectSearch,
  Input,
  TextArea,
  ActionButton,
  BackButton,
  InputCurrency,
} from "../../../../components";
import { Card, Nav, Tab, Row, Col, FormFile } from "react-bootstrap";

// API
import { CustomerApi } from "../../../../api";
import axios from "axios";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// File
import ImageViewer from "react-simple-image-viewer";
import { RupiahConvert } from "../../../../utilities";

const CRUDCustomer = ({ setNavbarTitle }) => {
  // Variables
  const title = "Customer";
  const { tipe, id_customer } = useParams();
  const history = useHistory();
  const location = useLocation();
  const routerState = location.state;

  // States
  const [data, setData] = useState({});
  const [page, setPage] = useState({
    loading: false,
    status: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    type: "",
    title: "",
    text: "",
  });
  // ** Atribut
  const [atribut, setAtribut] = useState({
    loading: true,
    status: false,
  });
  const [loadingAtribut, setLoadingAtribut] = useState({
    no_baru: false,
    kabupaten: false,
    kecamatan: false,
    desa: false,
  });
  const [dataAtribut, setDataAtribut] = useState({
    jenis: [],
    provinsi: [],
    bank: [],
    kabupaten: [],
    kecamatan: [],
    desa: [],
  });
  // ** /Atribut
  // ** File
  const [selectedFileNIK, setSelectedFileNIK] = useState();
  const [selectedFileNPWP, setSelectedFileNPWP] = useState();
  const [nameFileNIK, setNameFileNIK] = useState();
  const [nameFileNPWP, setNameFileNPWP] = useState();
  // ** /File

  const loadAtribut = (data) => {
    setAtribut({
      loading: true,
      status: true,
    });

    axios
      .all([
        CustomerApi.getJenis(),
        CustomerApi.getProvinsi(),
        CustomerApi.getBank(),
        data?.id_provinsi && CustomerApi.getKabupaten(data.id_provinsi),
        data?.id_kabupaten && CustomerApi.getKecamatan(data.id_kabupaten),
        data?.id_kecamatan && CustomerApi.getDesa(data.id_kecamatan),
      ])
      .then(
        axios.spread((jenis, provinsi, bank, kabupaten, kecamatan, desa) => {
          setDataAtribut({
            jenis: jenis.data.data,
            provinsi: provinsi.data.data,
            bank: bank.data.data,
            kabupaten: data?.id_provinsi ? kabupaten.data.data : [],
            kecamatan: data?.id_provinsi ? kecamatan.data.data : [],
            desa: data?.id_provinsi ? desa.data.data : [],
          });

          setAtribut({
            loading: false,
            status: true,
          });
        })
      )
      .catch(() =>
        setAtribut({
          loading: false,
          status: false,
        })
      );
  };

  useEffect(() => {
    if (tipe !== "tambah") {
      setPage({
        loading: true,
        status: true,
      });

      CustomerApi.single({ id_customer })
        .then((res) => {
          const data = res.data.data;
          setData(data);
          loadAtribut(data);

          setPage({
            loading: false,
            status: true,
          });
        })
        .catch(() =>
          setPage({
            loading: false,
            status: false,
          })
        );
    } else {
      loadAtribut(data);
    }

    setNavbarTitle(title);
  }, [setNavbarTitle]);

  const getNoBaru = (id_jenis_customer, setValues, values) => {
    setLoadingAtribut({
      ...loadingAtribut,
      no_baru: true,
    });

    CustomerApi.no_baru({ id_jenis_customer })
      .then((res) =>
        setValues({
          ...values,
          id_jenis_customer,
          kode_customer: res.data.data,
        })
      )
      .catch(() =>
        setValues({
          ...values,
          id_jenis_customer,
          kode_customer: "",
        })
      )
      .finally(() =>
        setLoadingAtribut({
          ...loadingAtribut,
          no_baru: false,
        })
      );
  };

  const InputFileNIK = () => {
    const [preview, setPreview] = useState();

    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const filesViewer = [preview];

    useEffect(() => {
      if (!selectedFileNIK) {
        setPreview(undefined);
        return;
      }

      const objectUrl = URL.createObjectURL(selectedFileNIK);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFileNIK]);

    const onSelectFile = (e) => {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedFileNIK(undefined);
        setNameFileNIK(undefined);

        return;
      }

      setSelectedFileNIK(e.target.files[0]);
      setNameFileNIK(e.target.files[0].name);
    };

    const closeImageViewer = () => {
      setIsViewerOpen(false);
    };
    return (
      <>
        <small>Upload Foto KTP</small>
        <FormFile custom style={{ zIndex: 0 }}>
          <FormFile.Input size="sm" onChange={onSelectFile} />
          <FormFile.Label style={{ fontSize: 14 }}>
            {nameFileNIK ? nameFileNIK : "Pilih Gambar"}
          </FormFile.Label>
        </FormFile>
        {selectedFileNIK && (
          <img
            src={preview}
            onClick={() => {
              setIsViewerOpen(true);
            }}
            style={{ height: 150 }}
            className="mt-4 mb-4"
          />
        )}
        {isViewerOpen && (
          <ImageViewer
            src={filesViewer}
            currentIndex={0}
            disableScroll={false}
            onClose={closeImageViewer}
          />
        )}
      </>
    );
  };

  const InputFileNPWP = () => {
    const [preview, setPreview] = useState();

    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const filesViewer = [preview];

    useEffect(() => {
      if (!selectedFileNPWP) {
        setPreview(undefined);
        return;
      }

      const objectUrl = URL.createObjectURL(selectedFileNPWP);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFileNPWP]);

    const onSelectFile = (e) => {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedFileNPWP(undefined);
        setNameFileNPWP(undefined);

        return;
      }

      setSelectedFileNPWP(e.target.files[0]);
      setNameFileNPWP(e.target.files[0].name);
    };

    const closeImageViewer = () => {
      setIsViewerOpen(false);
    };
    return (
      <>
        <small>Upload Foto NPWP</small>
        <FormFile custom style={{ zIndex: 0 }}>
          <FormFile.Input size="sm" onChange={onSelectFile} />
          <FormFile.Label style={{ fontSize: 14 }}>
            {nameFileNPWP ? nameFileNPWP : "Pilih Gambar"}
          </FormFile.Label>
        </FormFile>
        {selectedFileNPWP && (
          <img
            src={preview}
            onClick={() => {
              setIsViewerOpen(true);
            }}
            style={{ height: 150 }}
            className="mt-4 mb-4"
          />
        )}
        {isViewerOpen && (
          <ImageViewer
            src={filesViewer}
            currentIndex={0}
            disableScroll={false}
            onClose={closeImageViewer}
          />
        )}
      </>
    );
  };

  const getDataKabupaten = (id) => {
    setLoadingAtribut({
      ...loadingAtribut,
      kabupaten: true,
      kecamatan: true,
      desa: true,
    });

    CustomerApi.getKabupaten(id)
      .then((res) =>
        setDataAtribut({
          ...dataAtribut,
          kabupaten: res.data.data,
        })
      )
      .catch((err) => alert(err.response.data.message))
      .finally(() =>
        setLoadingAtribut({
          ...loadingAtribut,
          kabupaten: false,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getDataKecamatan = (id) => {
    setLoadingAtribut({
      ...loadingAtribut,
      kabupaten: true,
      kecamatan: true,
      desa: true,
    });

    CustomerApi.getKecamatan(id)
      .then((res) =>
        setDataAtribut({
          ...dataAtribut,
          kecamatan: res.data.data,
        })
      )
      .catch((err) => alert(err.response.data.message))
      .finally(() =>
        setLoadingAtribut({
          ...loadingAtribut,
          kabupaten: false,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getDataDesa = (id) => {
    setLoadingAtribut({
      ...loadingAtribut,
      kabupaten: true,
      kecamatan: true,
      desa: true,
    });

    CustomerApi.getDesa(id)
      .then((res) =>
        setDataAtribut({
          ...dataAtribut,
          desa: res.data.data,
        })
      )
      .catch((err) => alert(err.response.data.message))
      .finally(() =>
        setLoadingAtribut({
          ...loadingAtribut,
          kabupaten: false,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const formInitialValues = {
    kode_customer: data.kode_customer,
    nama_customer: data.nama_customer,
    id_jenis_customer: data.id_jenis_customer,
    keterangan: data.keterangan,
    telepon: data.telepon,
    email: data.email,
    media_sosial: data.media_sosial,
    alamat: data.alamat,
    id_provinsi: data.id_provinsi,
    nama_provinsi: data.nama_provinsi,
    id_kabupaten: data.id_kabupaten,
    nama_kabupaten: data.nama_kabupaten,
    id_kecamatan: data.id_kecamatan,
    nama_kecamatan: data.nama_kecamatan,
    id_desa: data.id_desa,
    nama_desa: data.nama_desa,
    kode_pos: data.kode_pos,
    kode_bank: data.kode_bank,
    atas_nama: data.atas_nama,
    no_rekening: data.no_rekening,
    nik: data.nik,
    npwp: data.npwp,
    kredit_limit: data.kredit_limit,
  };

  const formValidationSchema = Yup.object().shape({
    kode_customer: Yup.string().required("Kode Customer wajib diisi"),
    nama_customer: Yup.string().required("Nama Customer wajib diisi"),
    id_jenis_customer: Yup.string().required("Jenis Customer wajib diisi"),
    telepon: Yup.number("Format angka salah").required("No. Telepon wajib diisi"),
    // email: Yup.string().email('Format email salah').required("Email wajib diisi"),
    alamat: Yup.string().required("Alamat wajib diisi"),
    id_provinsi: Yup.string().required("Provinsi wajib diisi"),
    id_kabupaten: Yup.string().required("Kabupaten wajib diisi"),
    id_kecamatan: Yup.string().required("Kecamatan wajib diisi"),
    id_desa: Yup.string().required("Desa wajib diisi"),
    kode_pos: Yup.string().required("Kode Pos wajib diisi"),
    // kode_bank: Yup.string().required("Bank wajib diisi"),
    // atas_nama: Yup.string().required("Nama Nasabah wajib diisi"),
    // no_rekening: Yup.string().required("No. Rekening wajib diisi"),
  });

  const formSubmitHandler = (values) => {
    if (tipe === "tambah") {
      const KTP = [selectedFileNIK];

      const formDataKTP = new FormData();

      KTP.reduce((acc, file, index) => {
        formDataKTP.append("file", file);
        return file;
      }, {});

      let valueUpload = {
        ...values,
        keterangan: values.keterangan,
      };
      if (selectedFileNIK && selectedFileNPWP) {
        CustomerApi.uploadKTP(formDataKTP).then((data) => {
          const NPWP = [selectedFileNPWP];
          const formDataNPWP = new FormData();

          NPWP.reduce((acc, file, index) => {
            formDataNPWP.append("file", file);
            return file;
          }, {});

          let dataFotoKTP = data.data.data;

          CustomerApi.uploadNPWP(formDataNPWP).then((data) => {
            let valueUpload = {
              ...values,
              keterangan: values.keterangan,
              foto_ktp: dataFotoKTP,
              foto_npwp: data.data.data,
            };
            CustomerApi.create(valueUpload)
              .then(() => {
                // konfigurasi alert
                setAlertConfig({
                  show: true,
                  type: "success",
                  text: "Tambah data berhasil!",
                });
              })
              .catch((err) => {
                // konfigurasi alert
                setAlertConfig({
                  show: true,
                  type: "error",
                  text: `Tambah data gagal! ${err.response.data.message}`,
                });
              });
          });
        });
      } else if (selectedFileNIK) {
        CustomerApi.uploadKTP(formDataKTP).then((data) => {
          let valueUpload = {
            ...values,
            keterangan: values.keterangan,
            foto_ktp: data.data.data,
          };
          CustomerApi.create(valueUpload)
            .then(() => {
              // konfigurasi alert
              setAlertConfig({
                show: true,
                type: "success",
                text: "Tambah data berhasil!",
              });
            })
            .catch((err) => {
              // konfigurasi alert
              setAlertConfig({
                show: true,
                type: "error",
                text: `Tambah data gagal! ${err.response.data.message}`,
              });
            });
        });
      } else if (selectedFileNPWP) {
        const NPWP = [selectedFileNPWP];
        const formDataNPWP = new FormData();

        NPWP.reduce((acc, file, index) => {
          formDataNPWP.append("file", file);
          return file;
        }, {});
        CustomerApi.uploadNPWP(formDataNPWP).then((data) => {
          let valueUpload = {
            ...values,
            keterangan: values.keterangan,
            foto_npwp: data.data.data,
          };
          CustomerApi.create(valueUpload)
            .then(() => {
              // konfigurasi alert
              setAlertConfig({
                show: true,
                type: "success",
                text: "Tambah data berhasil!",
              });
            })
            .catch((err) => {
              // konfigurasi alert
              setAlertConfig({
                show: true,
                type: "error",
                text: `Tambah data gagal! ${err.response.data.message}`,
              });
            });
        });
      } else {
        CustomerApi.create(valueUpload)
          .then(() => {
            // konfigurasi alert
            setAlertConfig({
              show: true,
              type: "success",
              text: "Tambah data berhasil!",
            });
          })
          .catch((err) => {
            // konfigurasi alert
            setAlertConfig({
              show: true,
              type: "error",
              text: `Tambah data gagal! ${err.response.data.message}`,
            });
          });
      }
    } else if (tipe === "ubah") {
      const KTP = [selectedFileNIK];

      const formDataKTP = new FormData();

      KTP.reduce((acc, file, index) => {
        formDataKTP.append("file", file);
        return file;
      }, {});

      let valueUpload = {
        ...values,
        id_customer: data.id_customer,
        keterangan: values.keterangan,
      };
      if (selectedFileNIK && selectedFileNPWP) {
        CustomerApi.uploadKTP(formDataKTP).then((value) => {
          const NPWP = [selectedFileNPWP];
          const formDataNPWP = new FormData();

          NPWP.reduce((acc, file, index) => {
            formDataNPWP.append("file", file);
            return file;
          }, {});

          let dataFotoKTP = value.data.data;

          CustomerApi.uploadNPWP(formDataNPWP).then((value) => {
            let valueUpload = {
              ...values,
              id_customer: data.id_customer,
              keterangan: values.keterangan,
              foto_ktp: dataFotoKTP,
              foto_npwp: value.data.data,
            };
            CustomerApi.update(valueUpload)
              .then(() => {
                // konfigurasi alert
                setAlertConfig({
                  show: true,
                  type: "success",
                  text: "Ubah data berhasil!",
                });
              })
              .catch((err) => {
                // konfigurasi alert
                setAlertConfig({
                  show: true,
                  type: "error",
                  text: `Ubah data gagal! ${err.response.data.message}`,
                });
              });
          });
        });
      } else if (selectedFileNIK) {
        CustomerApi.uploadKTP(formDataKTP).then((value) => {
          let valueUpload = {
            ...values,
            id_customer: data.id_customer,
            keterangan: values.keterangan,
            foto_ktp: value.data.data,
          };
          CustomerApi.update(valueUpload)
            .then(() => {
              // konfigurasi alert
              setAlertConfig({
                show: true,
                type: "success",
                text: "Ubah data berhasil!",
              });
            })
            .catch((err) => {
              // konfigurasi alert
              setAlertConfig({
                show: true,
                type: "error",
                text: `Ubah data gagal! ${err.response.data.message}`,
              });
            });
        });
      } else if (selectedFileNPWP) {
        const NPWP = [selectedFileNPWP];
        const formDataNPWP = new FormData();

        NPWP.reduce((acc, file, index) => {
          formDataNPWP.append("file", file);
          return file;
        }, {});
        CustomerApi.uploadNPWP(formDataNPWP).then((value) => {
          let valueUpload = {
            ...values,
            id_customer: data.id_customer,
            keterangan: values.keterangan,
            foto_npwp: value.data.data,
          };
          CustomerApi.update(valueUpload)
            .then(() => {
              // konfigurasi alert
              setAlertConfig({
                show: true,
                type: "success",
                text: "Ubah data berhasil!",
              });
            })
            .catch((err) => {
              // konfigurasi alert
              setAlertConfig({
                show: true,
                type: "error",
                text: `Ubah data gagal! ${err.response.data.message}`,
              });
            });
        });
      } else {
        CustomerApi.update(valueUpload)
          .then(() => {
            // konfigurasi alert
            setAlertConfig({
              show: true,
              type: "success",
              text: "Ubah data berhasil!",
            });
          })
          .catch((err) => {
            // konfigurasi alert
            setAlertConfig({
              show: true,
              type: "error",
              text: `Ubah data gagal! ${err.response.data.message}`,
            });
          });
      }
    }
  };

  const List = ({ label, children }) => (
    <div className="mb-2">
      <small>{label}</small>
      <br />
      <b>{children}</b>
    </div>
  );

  if (tipe === "tambah" || tipe === "ubah" || tipe === "detail") {
    return (
      <>
        {!page.loading && !atribut.loading ? (
          page.status && atribut.status ? (
            <>
              <PopUpAlert
                show={alertConfig.show}
                onConfirm={() => {
                  setAlertConfig({
                    ...alertConfig,
                    show: false,
                  });

                  alertConfig.type === "success" &&
                    history.push("/crm/master/customer", { ...routerState });
                }}
                onHide={() =>
                  setAlertConfig({
                    ...alertConfig,
                    show: false,
                  })
                }
                title={alertConfig.title}
                text={alertConfig.text}
                type={alertConfig.type}
              />
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
                onValidationError={(e) => console.log(e)}
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                  setValues,
                  setFieldValue,
                  validateForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-between align-items-end mb-2">
                      <h5 className="text-capitalize font-weight-bold">
                        {tipe} {title}
                      </h5>
                      <BackButton
                        onClick={() => history.push("/crm/master/customer", { ...routerState })}
                      />
                    </div>

                    <Card>
                      <Tab.Container defaultActiveKey="umum">
                        <Card.Header>
                          <Nav variant="tabs" className="flex-row">
                            <Nav.Item>
                              <Nav.Link eventKey="umum">Informasi Umum</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="administratif">Administratif</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="kontak">Kontak</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="alamat">Alamat</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="bank">Bank</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Header>
                        <Card.Body>
                          <Tab.Content>
                            <Tab.Pane eventKey="umum">
                              <Row>
                                <Col md>
                                  {tipe !== "detail" ? (
                                    <>
                                      <Row>
                                        <Col lg>
                                          <SelectSearch
                                            label="Jenis Customer"
                                            name="id_jenis_customer"
                                            placeholder="Pilih Jenis Customer"
                                            onChange={(val) =>
                                              getNoBaru(val.value, setValues, values)
                                            }
                                            option={dataAtribut.jenis.map((val) => {
                                              return {
                                                value: val.id_jenis_customer,
                                                label: val.nama_jenis_customer,
                                              };
                                            })}
                                            defaultValue={
                                              values.id_jenis_customer
                                                ? dataAtribut.jenis.map((val) => {
                                                    if (
                                                      val.id_jenis_customer ===
                                                      values.id_jenis_customer
                                                    ) {
                                                      return {
                                                        value: val.id_jenis_customer,
                                                        label: val.nama_jenis_customer,
                                                      };
                                                    }
                                                  })
                                                : ""
                                            }
                                            error={
                                              errors.id_jenis_customer &&
                                              touched.id_jenis_customer &&
                                              true
                                            }
                                            errorText={
                                              errors.id_jenis_customer &&
                                              touched.id_jenis_customer &&
                                              errors.id_jenis_customer
                                            }
                                            loading={loadingAtribut.jenis}
                                          />
                                        </Col>
                                        <Col lg>
                                          <Input
                                            label="Kode Customer"
                                            type="text"
                                            name="kode_customer"
                                            placeholder={
                                              loadingAtribut.no_baru
                                                ? "Menunggu..."
                                                : "Kode Customer"
                                            }
                                            value={values.kode_customer}
                                            readOnly={true}
                                            onChange={handleChange}
                                            error={
                                              errors.kode_customer && touched.kode_customer && true
                                            }
                                            errorText={errors.kode_customer}
                                          />
                                        </Col>
                                      </Row>

                                      <Input
                                        label="Nama Customer"
                                        type="text"
                                        name="nama_customer"
                                        placeholder="Nama Customer"
                                        value={values.nama_customer}
                                        onChange={handleChange}
                                        error={
                                          errors.nama_customer && touched.nama_customer && true
                                        }
                                        errorText={errors.nama_customer}
                                      />
                                      {console.log(values)}
                                      {/* <Input
                                        label="Kredit Limit"
                                        type="text"
                                        name="kredit_limit"
                                        placeholder="Kredit Limit"
                                        value={
                                          RupiahConvert(
                                            parseInt(values.kredit_limit ?? 0).toString()
                                          ).detail
                                        }
                                        onChange={(val) => {
                                          const { value } = val.target;
                                          const onlyNumber = value.replace(/[^\d]/g, "");
                                          val.target.value = RupiahConvert(onlyNumber).detail;
                                          setFieldValue("kredit_limit", onlyNumber);
                                        }}
                                        // error={errors.kredit_limit && touched.kredit_limit && true}
                                        // errorText={errors.kredit_limit}
                                      /> */}
                                      <InputCurrency
                                        label="Kredit Limit"
                                        placeholder="Masukan Kredit Limit"
                                        value={parseFloat(values.kredit_limit)}
                                        onChange={(e) => {
                                          setFieldValue("kredit_limit", e);
                                        }}
                                        // error={
                                        //   errors.harga_kesepakatan &&
                                        //   touched.harga_kesepakatan &&
                                        //   true
                                        // }
                                        // errorText={errors.harga_kesepakatan}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <List label="Jenis Customer">
                                        {dataAtribut.jenis.find(
                                          (val) => val.id_jenis_customer === data?.id_jenis_customer
                                        )?.nama_jenis_customer ?? "-"}
                                      </List>

                                      <List label="Kode Customer">
                                        {data?.kode_customer ?? "-"}
                                      </List>

                                      <List label="Nama Customer">
                                        {data?.nama_customer ?? "-"}
                                      </List>

                                      <List label="Kredit Limit">
                                        {
                                          RupiahConvert(
                                            parseFloat(data?.kredit_limit ?? 0).toString()
                                          ).getWithComa
                                        }
                                      </List>
                                    </>
                                  )}
                                </Col>

                                <Col md>
                                  {tipe !== "detail" ? (
                                    <TextArea
                                      label="Keterangan"
                                      type="text"
                                      name="keterangan"
                                      placeholder="Keterangan"
                                      value={values.keterangan}
                                      onChange={handleChange}
                                      rows={7}
                                    />
                                  ) : (
                                    <List label="Keterangan">{data?.keterangan ?? "-"}</List>
                                  )}
                                </Col>
                              </Row>
                            </Tab.Pane>

                            <Tab.Pane eventKey="kontak">
                              {tipe !== "detail" ? (
                                <>
                                  <Input
                                    label="No. Telepon"
                                    type="text"
                                    name="telepon"
                                    placeholder="No. Telepon"
                                    value={values.telepon}
                                    onChange={handleChange}
                                    error={errors.telepon && touched.telepon && true}
                                    errorText={errors.telepon}
                                  />

                                  <Input
                                    label="Email"
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    error={errors.email && touched.email && true}
                                    errorText={errors.email}
                                  />
                                </>
                              ) : (
                                <>
                                  <List label="No. Telepon">{data?.telepon ?? "-"}</List>

                                  <List label="Email">{data?.email ?? "-"}</List>
                                </>
                              )}
                            </Tab.Pane>

                            <Tab.Pane eventKey="administratif">
                              <Row>
                                <Col md>
                                  {tipe !== "detail" ? (
                                    <>
                                      <Input
                                        label="NIK"
                                        type="text"
                                        name="nik"
                                        placeholder="NIK"
                                        value={values.nik}
                                        onChange={handleChange}
                                        error={errors.nik && touched.nik && true}
                                        errorText={errors.nik}
                                      />
                                      <InputFileNIK />
                                    </>
                                  ) : (
                                    <>
                                      <List label="NIK">{data?.nik ?? "-"}</List>

                                      {data?.foto_ktp && (
                                        <img
                                          src={data.foto_ktp}
                                          style={{ height: 150 }}
                                          className="mt-4 mb-4"
                                        />
                                      )}
                                    </>
                                  )}
                                </Col>

                                <Col md>
                                  {tipe !== "detail" ? (
                                    <>
                                      <Input
                                        label="No. NPWP"
                                        type="text"
                                        name="npwp"
                                        placeholder="No. NPWP"
                                        value={values.npwp}
                                        onChange={handleChange}
                                        error={errors.npwp && touched.npwp && true}
                                        errorText={errors.npwp}
                                      />
                                      <InputFileNPWP />
                                    </>
                                  ) : (
                                    <>
                                      <List label="No. NPWP">{data?.npwp ?? "-"}</List>

                                      {data?.foto_npwp && (
                                        <img
                                          src={data.foto_npwp}
                                          style={{ height: 150 }}
                                          className="mt-4 mb-4"
                                        />
                                      )}
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </Tab.Pane>

                            <Tab.Pane eventKey="alamat">
                              <Row>
                                <Col md>
                                  {tipe !== "detail" ? (
                                    <>
                                      <SelectSearch
                                        label="Provinsi"
                                        placeholder="Pilih Provinsi"
                                        name="id_provinsi"
                                        option={dataAtribut.provinsi.map((val) => {
                                          return {
                                            value: val.id_provinsi,
                                            label: val.nama_provinsi,
                                          };
                                        })}
                                        onChange={(val) => {
                                          setValues({
                                            ...values,
                                            id_provinsi: val.value,
                                            id_kabupaten: "",
                                            id_kecamatan: "",
                                            id_desa: "",
                                          });

                                          getDataKabupaten(val.value);
                                        }}
                                        defaultValue={
                                          values.id_provinsi
                                            ? dataAtribut.provinsi.map((val) => {
                                                if (val.id_provinsi === values.id_provinsi) {
                                                  return {
                                                    value: val.id_provinsi,
                                                    label: val.nama_provinsi,
                                                  };
                                                }
                                              })
                                            : ""
                                        }
                                        error={errors.id_provinsi && touched.id_provinsi && true}
                                        errorText={
                                          errors.id_provinsi &&
                                          touched.id_provinsi &&
                                          errors.id_provinsi
                                        }
                                      />

                                      <SelectSearch
                                        label="Kabupaten"
                                        placeholder="Pilih Kabupaten"
                                        name="id_kabupaten"
                                        option={dataAtribut.kabupaten.map((val) => {
                                          return {
                                            value: val.id_kabupaten,
                                            label: val.nama_kabupaten,
                                          };
                                        })}
                                        onChange={(val) => {
                                          setValues({
                                            ...values,
                                            id_kabupaten: val.value,
                                            id_kecamatan: "",
                                            id_desa: "",
                                          });

                                          getDataKecamatan(val.value);
                                        }}
                                        defaultValue={
                                          values.id_kabupaten
                                            ? dataAtribut.kabupaten.map((val) => {
                                                if (val.id_kabupaten === values.id_kabupaten) {
                                                  return {
                                                    value: val.id_kabupaten,
                                                    label: val.nama_kabupaten,
                                                  };
                                                }
                                              })
                                            : ""
                                        }
                                        loading={loadingAtribut.kabupaten}
                                        error={errors.id_kabupaten && touched.id_kabupaten && true}
                                        errorText={
                                          errors.id_kabupaten &&
                                          touched.id_kabupaten &&
                                          errors.id_kabupaten
                                        }
                                      />

                                      <SelectSearch
                                        label="Kecamatan"
                                        placeholder="Pilih Kecamatan"
                                        name="id_kecamatan"
                                        option={dataAtribut.kecamatan.map((val) => {
                                          return {
                                            value: val.id_kecamatan,
                                            label: val.nama_kecamatan,
                                          };
                                        })}
                                        onChange={(val) => {
                                          setValues({
                                            ...values,
                                            id_kecamatan: val.value,
                                            id_desa: "",
                                          });

                                          getDataDesa(val.value);
                                        }}
                                        defaultValue={
                                          values.id_kecamatan
                                            ? dataAtribut.kecamatan.map((val) => {
                                                if (val.id_kecamatan === values.id_kecamatan) {
                                                  return {
                                                    value: val.id_kecamatan,
                                                    label: val.nama_kecamatan,
                                                  };
                                                }
                                              })
                                            : ""
                                        }
                                        loading={loadingAtribut.kecamatan}
                                        error={errors.id_kecamatan && touched.id_kecamatan && true}
                                        errorText={
                                          errors.id_kecamatan &&
                                          touched.id_kecamatan &&
                                          errors.id_kecamatan
                                        }
                                      />

                                      <SelectSearch
                                        label="Desa"
                                        placeholder="Pilih Desa"
                                        name="id_desa"
                                        // defaultValue={
                                        //   values.id_desa
                                        //     ? {
                                        //         value: values.id_desa,
                                        //         label: values.nama_desa,
                                        //       }
                                        //     : false
                                        // }
                                        option={dataAtribut.desa.map((val) => {
                                          return {
                                            value: val.id_desa,
                                            label: val.nama_desa,
                                          };
                                        })}
                                        onChange={(val) =>
                                          setValues({
                                            ...values,
                                            id_desa: val.value,
                                          })
                                        }
                                        defaultValue={
                                          values.id_desa
                                            ? dataAtribut.desa.map((val) => {
                                                if (val.id_desa === values.id_desa) {
                                                  return {
                                                    value: val.id_desa,
                                                    label: val.nama_desa,
                                                  };
                                                }
                                              })
                                            : ""
                                        }
                                        loading={loadingAtribut.desa}
                                        error={errors.id_desa && touched.id_desa && true}
                                        errorText={
                                          errors.id_desa && touched.id_desa && errors.id_desa
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <List label="Provinsi">
                                        {dataAtribut.provinsi.find(
                                          (val) => val.id_provinsi === data?.id_provinsi
                                        )?.nama_provinsi ?? "-"}
                                      </List>

                                      <List label="Kabupaten">
                                        {dataAtribut.kabupaten.find(
                                          (val) => val.id_kabupaten === data?.id_kabupaten
                                        )?.nama_kabupaten ?? "-"}
                                      </List>

                                      <List label="Kecamatan">
                                        {dataAtribut.kecamatan.find(
                                          (val) => val.id_kecamatan === data?.id_kecamatan
                                        )?.nama_kecamatan ?? "-"}
                                      </List>

                                      <List label="Desa">
                                        {dataAtribut.desa.find(
                                          (val) => val.id_desa === data?.id_desa
                                        )?.nama_desa ?? "-"}
                                      </List>
                                    </>
                                  )}
                                </Col>

                                <Col md>
                                  {tipe !== "detail" ? (
                                    <>
                                      <Input
                                        label="Kode Pos"
                                        type="text"
                                        name="kode_pos"
                                        placeholder="Kode Pos"
                                        value={values.kode_pos}
                                        onChange={handleChange}
                                        error={errors.kode_pos && touched.kode_pos && true}
                                        errorText={errors.kode_pos}
                                      />

                                      <TextArea
                                        label="Alamat"
                                        type="text"
                                        name="alamat"
                                        placeholder="Alamat"
                                        value={values.alamat}
                                        onChange={handleChange}
                                        error={errors.alamat && touched.alamat && true}
                                        errorText={errors.alamat}
                                        rows={8}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <List label="Kode Pos">{data?.kode_pos ?? "-"}</List>

                                      <List label="Alamat">{data?.alamat ?? "-"}</List>
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </Tab.Pane>

                            <Tab.Pane eventKey="bank">
                              {tipe !== "detail" ? (
                                <>
                                  <SelectSearch
                                    label="Bank"
                                    placeholder="Pilih Bank"
                                    onChange={(val) =>
                                      setValues({
                                        ...values,
                                        kode_bank: val.value,
                                      })
                                    }
                                    option={dataAtribut.bank.map((val) => {
                                      return {
                                        value: val.kode_bank,
                                        label: val.nama_bank,
                                      };
                                    })}
                                    defaultValue={
                                      values.kode_bank
                                        ? dataAtribut.bank.map((val) => {
                                            if (val.kode_bank === values.kode_bank) {
                                              return {
                                                value: val.kode_bank,
                                                label: val.nama_bank,
                                              };
                                            }
                                          })
                                        : ""
                                    }
                                    // error={errors.kode_bank && touched.kode_bank && true}
                                    // errorText={
                                    //   errors.kode_bank && touched.kode_bank && errors.kode_bank
                                    // }
                                  />

                                  <Input
                                    label="No. Rekening"
                                    type="text"
                                    name="no_rekening"
                                    placeholder="No. Rekening"
                                    value={values.no_rekening}
                                    onChange={handleChange}
                                    // error={errors.no_rekening && touched.no_rekening && true}
                                    // errorText={errors.no_rekening}
                                  />

                                  <Input
                                    label="Atas Nama"
                                    type="text"
                                    name="atas_nama"
                                    placeholder="Atas Nama"
                                    value={values.atas_nama}
                                    onChange={handleChange}
                                    // error={errors.atas_nama && touched.atas_nama && true}
                                    // errorText={errors.atas_nama}
                                  />
                                </>
                              ) : (
                                <>
                                  <List label="Bank">
                                    {dataAtribut.bank.find(
                                      (val) => val.kode_bank === data?.kode_bank
                                    )?.nama_bank ?? "-"}
                                  </List>

                                  <List label="No. Rekening">{data?.no_rekening ?? "-"}</List>

                                  <List label="Atas Nama">{data?.atas_nama ?? "-"}</List>
                                </>
                              )}
                            </Tab.Pane>
                          </Tab.Content>
                        </Card.Body>
                      </Tab.Container>
                    </Card>

                    {tipe !== "detail" && (
                      <div className="text-right mt-2">
                        <ActionButton
                          type="submit"
                          text="Simpan"
                          loading={isSubmitting}
                          onClick={() => {
                            validateForm().then((e) => {
                              if (Object.keys(e).length > 0) {
                                const data = e;
                                setAlertConfig({
                                  show: true,
                                  type: "error",
                                  title: "Data tidak valid",
                                  text: data[Object.keys(data)[0]],
                                });
                              } else {
                                handleSubmit();
                              }
                            });
                          }}
                        />
                      </div>
                    )}
                  </form>
                )}
              </Formik>
            </>
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )
        ) : (
          <DataStatus loading={true} text="Menunggu..." />
        )}
      </>
    );
  } else {
    return <DataStatus text="Data gagal dimuat" />;
  }
};

export default CRUDCustomer;
