import {
  IoServerOutline,
  IoCashOutline,
  IoSpeedometerOutline,
  IoPersonCircleOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";
import Logo from "../assets/image/LogoTJS.jpeg";
import { ListCustomer, JenisCustomer, CRUDCustomer } from "../pages";
import { LaporanCustomer } from "pages/Crm";

export default {
  LOGO: Logo,
  MODUL: "CRM",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ["CRM"],
    },
    {
      text: "Master Data",
      type: "dropdown",
      icon: <IoServerOutline />,
      hak: ["CRM", "CRM_MAS_JEN", "CRM_MAS_CUS"],
      menu: [
        {
          text: "Jenis Customer",
          link: "/crm/master/jenis-customer",
          hak: ["CRM_MAS_JEN"],
        },
        {
          text: "Customer",
          link: "/crm/master/customer",
          hak: ["CRM_MAS_CUS"],
        },
      ],
    },
    {
      text: "Laporan",
      type: "dropdown",
      icon: <IoDocumentTextOutline />,
      hak: ["SUPA", "CRM_RPT_LCS"],
      menu: [
        {
          text: "Laporan Customer",
          link: "/crm/laporan/laporan-customer",
          hak: ["SUPA", "CRM_RPT_LCS"],
        },
      ],
    },

    // {
    //   text: "Transaksi",
    //   type: "dropdown",
    //   icon: <IoCashOutline />,
    //   hak: ["ANG"],
    //   menu: [
    //     {
    //       text: "Program",
    //       link: "/anggaran/transaksi/program",
    //       hak: ["ANG"],
    //     },
    //     {
    //       text: "Kegiatan",
    //       link: "/anggaran/transaksi/kegiatan",
    //       hak: ["ANG"],
    //     },
    //     {
    //       text: "Realisasi",
    //       link: "/anggaran/transaksi/realisasi",
    //       hak: ["ANG"],
    //     },
    //   ],
    // },
    // {
    //   text: "Profil",
    //   type: "dropdown",
    //   icon: <IoPersonCircleOutline />,
    //   hak: ["CRM"],
    //   menu: [
    //     {
    //       text: "Akun Saya",
    //       link: "/profil",
    //       hak: ["CRM"],
    //     },
    //     {
    //       text: "Ubah Akun",
    //       link: "/profil/ubah",
    //       hak: ["CRM"],
    //     },
    //     {
    //       text: "Ganti Password",
    //       link: "/profil/ganti-password",
    //       hak: ["CRM"],
    //     },
    //   ],
    // },
  ],

  ROUTES: [
    {
      exact: true,
      path: "/crm/master/jenis-customer",
      page: JenisCustomer,
      hak: ["CRM_MAS_JEN"],
    },

    // Customer
    {
      exact: true,
      path: "/crm/master/customer",
      page: ListCustomer,
      hak: ["CRM_MAS_CUS"],
    },
    {
      exact: true,
      path: "/crm/master/customer/:tipe",
      page: CRUDCustomer,
      hak: ["CRM_MAS_CUS"],
    },
    {
      exact: true,
      path: "/crm/master/customer/:tipe/:id_customer",
      page: CRUDCustomer,
      hak: ["CRM_MAS_CUS"],
    },

    // Laporan
    // Laporan Customer
    {
      exact: true,
      path: "/crm/laporan/laporan-customer",
      page: LaporanCustomer,
      hak: ["CRM", "CRM_RPT_LCS"],
    },
  ],
};
