import Services from "../../services";

class AuthApi {
  login(value) {
    return Services.post("/crm/auth/login", value);
  }

  profile() {
    return Services.get("/crm/auth/profile");
  }

}

export default new AuthApi();
