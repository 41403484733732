import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch, Select } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { PostingStockOpnameApi } from "api";
import { range } from "lodash";

const ModalFilter = ({ show, setShow, data, setData, dropdown }) => {
  const yearNow = DateConvert(new Date()).defaultYear;
  const monthOptions = [
    { label: "Januari", value: "01" },
    { label: "Februari", value: "02" },
    { label: "Maret", value: "03" },
    { label: "April", value: "04" },
    { label: "Mei", value: "05" },
    { label: "Juni", value: "06" },
    { label: "Juli", value: "07" },
    { label: "Agustus", value: "08" },
    { label: "September", value: "09" },
    { label: "Oktober", value: "10" },
    { label: "November", value: "11" },
    { label: "Desember", value: "12" },
  ];

  const yearOptions = range(2000, parseInt(yearNow + 1)).map((val) => ({
    label: val,
    value: val,
  }));

  // FORM VALUES
  const formInitialValues = {
    bulan: data?.filter?.bulan,
    tahun: data?.filter?.tahun,
    id_jenis_customer: data?.filter?.id_jenis_customer,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      bulan: undefined,
      tahun: undefined,
      id_jenis_customer: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <SelectSearch
            label="Bulan"
            placeholder="Pilih bulan"
            option={monthOptions}
            defaultValue={monthOptions.find(
              (val) => val.value.toString() === values?.bulan?.toString()
            )}
            onChange={(val) => setFieldValue("bulan", val.value)}
          />
          <SelectSearch
            label="Tahun"
            placeholder="Pilih tahun"
            option={yearOptions}
            defaultValue={yearOptions.find(
              (val) => val.value.toString() === values?.tahun?.toString()
            )}
            onChange={(val) => setFieldValue("tahun", val.value)}
          />
          <SelectSearch
            key={values.id_jenis_customer}
            label="Jenis Customer"
            placeholder="Pilih jenis customer"
            defaultValue={dropdown?.jenisCustomer?.find(
              (val) => val.value === values.id_jenis_customer
            )}
            option={dropdown?.jenisCustomer}
            onChange={(val) => {
              setFieldValue("id_jenis_customer", val.value);
            }}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
