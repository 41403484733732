import Services from "../../../services"

class CustomerApi {
  get() {
    return Services.get("/crm/mastercustomer")
  }

  getPage(page, dataLength, key) {
    return Services.get(`/crm/mastercustomer/page/?per_page=${dataLength}&page=${page}&q=${key}`)
  }

  no_baru(params) {
    return Services.get("/crm/mastercustomer/no_baru", { params })
  }

  getJenis() {
    return Services.get("/crm/jeniscustomer/dropdown")
  }

  getProvinsi() {
    return Services.get("/crm/wilayah/dropdown_provinsi")
  }

  getKabupaten(id) {
    return Services.get("/crm/wilayah/dropdown_kabupaten?id_provinsi=" + id)
  }

  getKecamatan(id) {
    return Services.get("/crm/wilayah/dropdown_kecamatan?id_kabupaten=" + id)
  }

  getDesa(id) {
    return Services.get("/crm/wilayah/dropdown_desa?id_kecamatan=" + id)
  }

  getBank() {
    return Services.get("/crm/bank/dropdown")
  }
  search(key) {
    return Services.get("/crm/mastercustomer/page/?per_page=10&page=1&q=" + key)
  }

  show(value) {
    return Services.put("/crm/mastercustomer/show", value)
  }

  hide(value) {
    return Services.put("/crm/mastercustomer/hide", value)
  }

  create(value) {
    return Services.post("/crm/mastercustomer", value)
  }

  update(value) {
    return Services.put("/crm/mastercustomer", value)
  }

  delete(value) {
    return Services.post("/crm/mastercustomer/delete", value)
  }

  uploadKTP(value) {
    return Services.post("/crm/upload/ktp", value)
  }

  uploadNPWP(value) {
    return Services.post("/crm/upload/npwp", value)
  }

  single(params) {
    return Services.get("/crm/mastercustomer/single", { params })
  }
}

export default new CustomerApi()
