// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Component
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  UpdateButton,
  ReadButton,
  DataStatus,
  Alert,
  Pagination,
  Switch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  DeleteButton,
  Tr,
  Th,
  Td,
  DeleteModal,
} from "../../../../components";
import { PageNumber } from "../../../../utilities";
import { Col, Row } from "react-bootstrap";

// API
import { CustomerApi } from "../../../../api";

const Customer = ({ setNavbarTitle }) => {
  const title = "Customer";
  const history = useHistory();
  const location = useLocation();

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  // data jenis
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);

  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    // request data ke server
    CustomerApi.getPage(page, dataLength, searchKey)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err.response.data.message))
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  // fungsi show/ hide
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_customer: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? CustomerApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : CustomerApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  const checkPaginationHandler = () =>
    new Promise((resolve, reject) => {
      const paginationFromBrowserState = location?.state?.pagination;

      if (Boolean(paginationFromBrowserState)) {
        setPage(paginationFromBrowserState.page);
        setDataLength(paginationFromBrowserState.dataLength);
        setTotalPage(paginationFromBrowserState.totalPage);
        setDataCount(paginationFromBrowserState.dataCount);

        resolve({
          page: paginationFromBrowserState.page,
          dataLength: paginationFromBrowserState.dataLength,
          totalPage: paginationFromBrowserState.totalPage,
          dataCount: paginationFromBrowserState.dataCount,
        });
      }

      reject({});
    });

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);

    checkPaginationHandler().finally(() => getData());
    // eslint-disable-next-line
  }, [setNavbarTitle]);

  useEffect(() => {
    getData();
  }, [page, dataLength, searchKey]);

  // Modal Hapus
  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const idData = deleteData.id_customer;
    const values = { id_customer: idData };

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      CustomerApi.delete(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! ${err.response.data.message}`,
          });
          setShowAlert(true);
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Customer : {deleteData.kode_customer}</div>
        <div>Nama Customer : {deleteData.nama_customer}</div>
      </DeleteModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Kode</ThFixed>
            <Th>Nama Customer</Th>
            <Th>Jenis Customer</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ReadButton
                    onClick={() =>
                      history.push(`/crm/master/customer/detail/${val.id_customer}`, {
                        pagination: {
                          page: page,
                          totalPage: totalPage,
                          dataCount: dataCount,
                          dataLength: dataLength,
                        },
                      })
                    }
                  />
                  <UpdateButton
                    onClick={() =>
                      history.push(`/crm/master/customer/ubah/${val.id_customer}`, {
                        pagination: {
                          page: page,
                          totalPage: totalPage,
                          dataCount: dataCount,
                          dataLength: dataLength,
                        },
                      })
                    }
                  />
                  <DeleteButton
                    onClick={() => {
                      setDeleteData(val);
                      setIsDeleteData(true);
                    }}
                  />
                  <Switch
                    id={toString(index + 1)}
                    checked={val.is_hidden === false ? true : false}
                    onChange={() => changeDataStatus(val.is_hidden, val.id_customer)}
                  />
                </div>
              </TdFixed>
              <TdFixed>{val.kode_customer}</TdFixed>
              <Td>{val.nama_customer}</Td>
              <Td>{val.nama_jenis_customer}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <CreateButton onClick={() => history.push("/crm/master/customer/tambah")} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* Table Section */}
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data ? (
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Server error" />
      )}

      {/* Modal */}
      <HapusModal />
    </CRUDLayout>
  );
};

export default Customer;
