import { InfoItemVertical } from "@bhawanadevteam/react-core";
import React from "react";
import { Col, Row } from "react-bootstrap";

export const AlamatSection = ({ data }) => {
  return (
    <Row>
      <Col>
        <InfoItemVertical label="Provinsi" text={data?.nama_provinsi ?? "-"} />
        <InfoItemVertical
          label="Kabupaten"
          text={data?.nama_kabupaten ?? "-"}
        />
        <InfoItemVertical
          label="Kecamatan"
          text={data?.nama_kecamatan ?? "-"}
        />
        <InfoItemVertical label="Desa" text={data?.nama_desa ?? "-"} />
      </Col>
      <Col>
        <InfoItemVertical label="Kode Pos" text={data?.kode_pos ?? "-"} />
        <InfoItemVertical label="Alamat" text={data?.alamat ?? "-"} />
      </Col>
    </Row>
  );
};
